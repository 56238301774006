.mypage {
  width: 80%;
  margin: 0 auto;
}
.mypage .nav-tabs {
  background-color: #f5d95c;
  display: flex;
  gap: 30px;
  justify-content: center;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 6px;
}

.mypage .nav-tabs button.active {
  color: #fc3c76;
  font-weight: bold;
}

.mypage .nav-tabs button {
  background: none;
  border: none;
  font-size: 16px;
}

.mypage .tab-content {
  width: 80%;
  margin: 0 auto;
}
