.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  box-sizing: border-box; /* 패딩과 테두리를 포함하여 높이와 너비를 계산 */
  z-index: 1000; /* 다른 요소들 위에 표시되도록 설정 */
  padding: 0 20px;
  background-color: #fff;
}
.Header .logo {
  position: relative;
  height: 100%;
  top: 20px;
}
.Header .logo .logo-image {
  width: 150px; /* 원하는 크기로 조정 */
  height: auto;
  position: absolute; /* 절대 위치 */
  z-index: 1001; /* 헤더와 네비게이션 바보다 더 위에 위치 */
  cursor: pointer;
}

.Header .nav-buttons {
  display: flex;
  margin-left: auto; /* 오른쪽으로 정렬 */
  gap: 20px;
  font-size: 16px;
}

.Header .nav-buttons button {
  padding: 8px 16px;
  background-color: #219c90;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.Header .nav-buttons .nav-dropdown {
  position: relative;
}

.Header .nav-buttons .nav-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 2000;
}

.Header .nav-buttons .nav-dropdown:hover .dropdown-menu,
.Header .nav-buttons .nav-dropdown .dropdown-menu:hover {
  display: block;
}

.Header .nav-buttons .nav-dropdown:hover .dropdown-menu button {
  display: block;
  background-color: white; /* dropdown-menu 안의 button은 기본 배경 흰색 */
  color: black; /* 버튼 글씨 색 */
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
}

.Header .nav-buttons .nav-dropdown .dropdown-menu button:hover {
  background: rgb(248, 246, 246);
}

.Header .nav-buttons .nav-dropdown .dropdown-menu #logout {
  color: #0f7e73;
}

@media (max-width: 600px) {
  .Header {
    height: 60px;
    padding: 0 10px;
  }
  .Header .logo {
    top: 10px;
  }
  .Header .logo .logo-image {
    width: auto;
    height: 50px;
  }
  .Header .nav-buttons button {
    padding: 6px 12px;
    font-size: 14px;
  }
}
