.meme-slider-container {
  position: relative;
  width: 100%;
  padding: 0 50px;
  margin: 20px 0;
  box-sizing: border-box;
}

.meme-swiper {
  position: relative;
  width: 100%;
}

.meme-slide {
  height: 200px;
  width: 200px !important; /* 슬라이드 너비 고정 */
}

.meme-image {
  width: 200px !important; /* 이미지 너비 고정 */
  height: 200px !important; /* 이미지 높이 고정 */
  object-fit: contain; /* 이미지 비율 유지하면서 컨테이너에 맞춤 */
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.meme-image:hover {
  transform: scale(1.05);
}

/* 네비게이션 버튼 커스터마이징 */
.meme-slider-container .swiper-button-prev,
.meme-slider-container .swiper-button-next {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  color: #333;
}

.meme-slider-container .swiper-button-prev {
  left: 0;
}

.meme-slider-container .swiper-button-next {
  right: 0;
}

.meme-slider-container .swiper-button-prev:after,
.meme-slider-container .swiper-button-next:after {
  font-size: 20px;
  font-weight: bold;
}

/* 버튼 호버 효과 */
.meme-slider-container .swiper-button-prev:hover,
.meme-slider-container .swiper-button-next:hover {
  background-color: rgba(255, 255, 255, 1);
}

/* 비활성화된 버튼 스타일 */
.meme-slider-container .swiper-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* 반응형 스타일링 */
@media (max-width: 768px) {
  .meme-slider-container {
    padding: 0 30px;
  }

  .meme-slide {
    height: 150px;
  }

  .meme-slider-container .swiper-button-prev,
  .meme-slider-container .swiper-button-next {
    width: 30px;
    height: 30px;
  }

  .meme-slider-container .swiper-button-prev:after,
  .meme-slider-container .swiper-button-next:after {
    font-size: 16px;
  }
}
