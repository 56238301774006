/* 공통 스타일 정의 */
.findMemePost, .findMemeEdit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    max-width: 1200px;
}

.findMemePost form, .findMemeEdit form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.findMemePost h2, .findMemeEdit h2 {
    margin-bottom: 20px;
}

.findMemePost input, .findMemeEdit input {
    font-size: 16px;
    padding: 8px 12px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}

.custom-editor {
    width: 100%;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.custom-editor .ql-container {
    min-height: 400px; 
}

.custom-editor .ql-editor {
    min-height: 400px;
    overflow-y: auto; /* 내용이 길어질 경우 스크롤이 생기도록 설정 */
}

.findMemePost-tag {
    width: 100%;
    margin-bottom: 20px; /* 폼 요소와 등록 버튼 사이의 간격 */
}
.findMemePost-tag-top{
    display: flex;
    gap: 10px;
}

.findMemePost-tag select {
    padding: 8px;
    font-size: 16px;
    margin-bottom: 10px;
}

.findMemePost-tag .tag-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* 버튼 사이의 간격 */
}
.selected-tags{
    display: flex;
    gap: 10px;
}

.findMemePost-tag button {
    background-color: #219C90;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
}

.findMemePost-tag .selected-tag {
    display: flex;
    align-items: center;
    background-color: #e0f4f1;
    border: 1px solid #219C90;
    border-radius: 20px;
    padding: 4px 16px;
    margin-bottom: 10px;
}

.findMemePost-tag .selected-tag button {
    border: none;
    color: white;
    border-radius: 4px;
    padding: 2px 6px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 12px;
}

.findMemePost-tag .selected-tag button:hover {
    background-color: #e60000;
}

.findMemePost-btn, .findMemeEdit-btn {
    width: 100px;
    height: 40px;
    border: none;
    background-color: #219C90;
    font-size: 16px;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}

/* 버튼에 호버 효과 추가 */
.findMemePost-btn:hover, .findMemeEdit-btn:hover {
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.4), 0px 2px 4px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
}

/* 버튼을 클릭했을 때의 효과 */
.findMemePost-btn:active, .findMemeEdit-btn:active {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.3);
    transform: translateY(1px);
}


