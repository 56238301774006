.signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* 수평 중앙 정렬 */
  margin: 0 auto;
}
.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
}
.form-group label {
  display: flex;
  font-size: 14px;
  flex-direction: flex-start;
  color: rgb(170, 170, 170);
  margin-bottom: 6px;
}
.form-group input {
  width: 400px;
  height: 40px;
  padding-left: 10px;
  margin-right: 90px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.form-group button {
  position: absolute;
  right: 0;
  top: 28px; /* 버튼을 입력창과 수직으로 정렬 */
  bottom: 0; /* 입력창과 수직 중앙 정렬 */
  height: 34px;
  padding: 0 10px;
  margin: 0;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #219c90;
  color: white;
  border: none;
  border-radius: 4px;
}
.form-group button:hover {
  background-color: #1a7a6d;
}
.form-group button:disabled {
  background-color: #d6d6d6; /* 비활성화된 버튼의 배경색 */
  color: #a0a0a0; /* 비활성화된 버튼의 텍스트 색상 */
  cursor: not-allowed; /* 비활성화된 버튼의 커서 모양 */
}
.error {
  color: red;
  font-size: 14px;
  margin: 0px 0px;
}

/* 모달창 스타일 */
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1001; /* 폼 위로 모달을 띄우기 위한 z-index */
}

/* 모달의 오버레이 스타일 */
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 배경 어둡게 처리 */
  z-index: 1000; /* 모달 배경은 모달 바로 아래에 있어야 하므로 1000 */
}

.signup-btn:hover {
  background-color: #1a7a6d;
}
