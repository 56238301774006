.comment-form-container {
    width: 90%;
    margin-top: 15px;
    padding: 10px;
    border-radius: 5px;
    min-height: 200px; 
  }
  
  .comment-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
    text-align: right;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }

  .custom-quill-editor .ql-editor {
    min-height: 150px;  /* 최소 높이 설정 */
    max-height: 300px;  /* 최대 높이 설정 */
    overflow-y: auto;   /* 내용이 많아지면 스크롤 */
  }
  
  