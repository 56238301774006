.findmeme {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 20px;
}

.findmeme-posts{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%; /* 부모 요소의 전체 너비 */
}

.post-summary{
    display: flex;
    flex-direction: column;
    padding: 10px 40px;
    border-bottom: 1px solid gray;
    width: 100%;
    text-align: left;
    height: 130px;
    box-sizing: border-box; /* 패딩과 경계선의 크기를 포함 */
}

.post-summary:hover{
    background-color:#f0f0f0;;
}
/* 링크 스타일 제거 */
.post-summary a {
    text-decoration: none; /* 밑줄 제거 */
    color: inherit; /* 기본 텍스트 색상 유지 */
    height: 100%; /* 링크를 전체 높이로 설정 */
}

.findmeme-posts-title {
    font-weight: bold;
    font-size: 20px;
    white-space: nowrap; /* 제목을 한 줄로 유지 */
    overflow: hidden; /* 넘치는 내용 숨기기 */
    text-overflow: ellipsis; /* 넘치는 내용을 생략부호로 대체 */
    border-bottom: 30px;
}

.findmeme-posts-content {
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 최대 두 줄까지 표시 */
    -webkit-box-orient: vertical; /* 수직 박스 정렬 */
    overflow: hidden; /* 넘치는 내용 숨기기 */
    text-overflow: ellipsis; /* 넘치는 내용을 생략부호로 대체 */
    margin-bottom: 10px; /* 내용과 작성자, 작성시간 사이의 여백 추가 */
    height: 3.2em; /* 두 줄 높이 설정 */
    line-height: 1.6em; /* 한 줄 높이 설정 (line-height에 맞게 조정) */
}

.findmeme-posts-other {
    font-size: 14px;
    color: #666; /* 색상 설정 (선택사항) */
    display: flex; /* flexbox 활성화 */
    justify-content: space-between; /* 양쪽 끝에 배치 */
    
}

.findmeme-btn{
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    width: 70%; /* 글 목록과 동일한 너비 */
    justify-content: flex-start; /* 버튼을 왼쪽 정렬 */
}

.findmeme-btn button {
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    background:none;
    cursor: pointer;
    font-size: 16px;
}
.findmeme-post-btn{
    width: 70%;
    border: none;
    background: none;
    cursor: pointer;
    text-align: right;
}
.pagination {
    margin-top: 20px; /* 페이지네이션과 게시글 목록 사이에 간격 추가 */
}

.pagination button.active {
    font-weight: bold; /* 현재 페이지 버튼을 강조 */
    background-color: #f0f0f0; /* 강조된 버튼 배경색 */
}

.findmeme-btn button.active .icon {
    color: #219C90; /* 아이콘 색상 변경 (예: 노란색) */
}

.findmeme-btn button .icon {
    transition: color 0.3s ease; /* 아이콘 색상 전환 애니메이션 */
}