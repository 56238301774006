.comment img,
.reply img {
  max-width: 80%; /* 부모 요소 크기를 넘지 않도록 설정 */
  height: auto; /* 비율 유지 */
  display: block; /* 인라인 요소로 간주되지 않게 설정 */
  margin: 10px auto; /* 텍스트와 간격 추가 */
  border-radius: 5px; /* 둥근 모서리 (선택 사항) */
}

.comments-section {
  margin-top: 20px;
}

.comment-container {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-sizing: border-box;
}

.comment-username {
  font-weight: bold;
  margin-bottom: 5px;
}

.reply-list {
  padding-left: 20px;
}

.reply {
  margin-top: 10px;
  padding: 5px;
  background-color: #e8e8e8;
  border-radius: 5px;
}

.reply-to {
  font-style: italic;
  margin-bottom: 5px;
}

.username {
  font-weight: bold;
}

.comment-form-container {
  margin-top: 10px;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.commentsCount {
  font-weight: bold;
  margin-bottom: 10px;
}

/* 채택된 댓글 스타일 */
.comment.selected,
.reply.selected {
  border: 2px solid #007bff; /* 채택된 댓글의 테두리 색상 */
  background-color: #f0f8ff; /* 채택된 댓글의 배경 색상 */
  font-weight: bold; /* 채택된 댓글의 글씨를 두껍게 */
}
