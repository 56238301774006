.masonry-grid {
  display: flex;
  margin-left: -10px; /* 열 간격 조절 */
  width: auto;
}

.masonry-column {
  padding-left: 20px; /* 각 열 간 간격 */
  background-clip: padding-box;
}
.meme-item {
  margin-bottom: 15px;
}

.meme-item .meme-image-container {
  position: relative;
  width: 100%; /* 부모가 이미지의 가로 크기와 같도록 설정 */
}

.meme-item .meme-image-container img {
  width: 100%; /* 가로 길이를 컨테이너에 맞춤 */
  height: auto; /* 세로 길이는 원본 비율에 따라 자동 조정 */
  object-fit: cover; /* 또는 cover로 변경해 확인해보세요 */
  display: block;
  transition: transform 0.3s ease-in-out;
}

/* 어두운 투명색 오버레이 */
.meme-item .meme-image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 검정색 */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

/* 마우스 오버 시 오버레이와 이미지 확대 효과 */
.meme-image-container:hover .overlay {
  opacity: 1; /* 오버레이가 나타나도록 설정 */
}

/* 하트 버튼을 오른쪽 하단에 위치시키기 위한 스타일 */
.meme-item .meme-image-container button {
  position: absolute;
  top: 10px; /* 아래쪽에서 10px 떨어짐 */
  right: 10px; /* 오른쪽에서 10px 떨어짐 */
  background: none; /* 배경색 없음 */
  border: none; /* 테두리 없음 */
  cursor: pointer; /* 클릭 가능한 포인터로 변경 */
  opacity: 0; /* 초기 상태에서는 하트가 보이지 않음 */
  transition: opacity 0.3s ease-in-out;
}

/* 마우스 오버 시 하트 버튼 보이게 */
.meme-item .meme-image-container:hover button {
  opacity: 1;
}

.meme-item .meme-image-container button svg {
  font-size: 36px; /* 아이콘 크기 */
  color: white; /* 아이콘 색상 */
  transition: transform 0.3s ease-in-out; /* 크기 변화 효과 */
}

/* 버튼 hover 시 확대 효과 */
.meme-item .meme-image-container button:hover svg {
  transform: scale(1.2); /* 1.2배 확대 */
}

/* 정보 텍스트 */
.meme-info {
  color: white;
  font-size: 16px;
  text-align: center;
}

.meme-info {
  display: flex;
  align-items: center; /* 아이콘과 텍스트를 수평선상 중앙 정렬 */
  gap: 8px; /* 아이콘과 숫자 사이의 간격 조절 */
}

.meme-info svg {
  /* 아이콘에 대한 스타일 */
  vertical-align: middle; /* 아이콘이 텍스트와 수평으로 정렬되도록 함 */
}

/* 태그를 이미지 바닥에 고정시키기 위한 스타일 */
.meme-tags {
  position: absolute;
  bottom: 14px; /* 이미지의 하단에 고정 */
  left: 14px; /* 좌측 정렬 */
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: flex-start; /* 태그들을 좌측 정렬 */
  gap: 8px; /* 태그들 사이 간격 */
  flex-wrap: wrap; /* 태그가 많을 때 줄바꿈 */
}
