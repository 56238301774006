.memedetail {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; /* 수평 가운데 정렬 */
}

.memedetail .memedetail-info {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  width: 100%; /* 부모의 너비를 100%로 설정하여 수평 가운데 정렬 */
  margin-bottom: 60px;
  align-items: center;
  gap: 30px;
}

.memedetail .memedetail-info .memedetail-left {
  border: 1px solid red;
  flex: 1; /* 비율을 1로 설정 (필요에 따라 조정 가능) */
}
.memedetail .memedetail-info .memedetail-left > img {
  width: 300px;
}
.memedetail .memedetail-info .memedetail-left .memedetail-left-info {
  gap: 10px;
  display: flex;
  align-items: center; /* 요소를 수직으로 가운데 정렬 */
  margin-top: 10px; /* 이미지와 간격 추가 */
  width: 100%; /* 이미지 너비에 맞추기 위해 100%로 설정 */
  text-align: center;
}
.memedetail
  .memedetail-info
  .memedetail-left
  .memedetail-left-info
  .memedetail-link {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none; /* 링크 밑줄 제거 */
  color: inherit; /* 부모 요소로부터 텍스트 색상 상속 */
}

.memedetail .memedetail-info .memedetail-left .memedetail-left-info img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.memedetail .memedetail-info .memedetail-left .memedetail-left-info span {
  font-size: 16px; /* 텍스트 크기 */
  display: flex;
  align-items: center; /* 텍스트를 수직으로 가운데 정렬 */
}

.memedetail .memedetail-info .memedetail-left .memedetail-left-info button {
  border: none;
  background: none;
  font-size: 30px;
  align-items: center;
  display: center;
  text-align: center;
}
.memedetail .memedetail-info .memedetail-right {
  flex: 1; /* 비율을 1로 설정 (필요에 따라 조정 가능) */
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center; /* 수평 가운데 정렬 */
  padding: 20px;
  background-color: #f5f5f5; /* 배경색 설정 */
  border-radius: 10px; /* 둥근 모서리 */
  border: 1px solid blue;
}
.memedetail .memedetail-info .memedetail-right .download-btn {
  border: none;
  width: 160px;
  height: 40px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #4caf50; /* 다운로드 버튼 배경색 */
  color: white; /* 텍스트 색상 */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* 아이콘과 텍스트 간격 */
}
.memedetail .memedetail-info .memedetail-right .share-menu {
  display: flex;
  justify-content: center;
  gap: 15px; /* 버튼 사이 간격 */
  padding: 10px;
  border-radius: 8px; /* 둥근 모서리 */
}

.memedetail .memedetail-info .memedetail-right .share-menu button {
  background-color: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #333; /* 아이콘 색상 */
  display: flex;
  align-items: center;
}

.memedetail .memedetail-info .memedetail-right .memedetail-tags {
  display: flex;
  gap: 10px;
}
