.findMemeDetail {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  align-items: center;
}
.findMemeDetail-title {
  display: flex; /* Flexbox 적용 */
  align-items: center; /* 아이템들을 수직 가운데 정렬 */
  gap: 12px; /* 아이콘과 제목 사이의 간격 */
  width: 100%;
  box-sizing: border-box; /* 패딩과 테두리를 포함한 높이 */
  margin-bottom: 10px;
}
.findMemeDetail-title svg {
  font-size: 40px; /* 원하는 크기로 설정 */
  color: #219c90; /* 아이콘 색상 변경 (선택사항) */
}
.findMemeDetail-title > div {
  flex-grow: 1; /* 제목이 남은 공간을 채우도록 설정 */
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 20px;
  text-align: left;
}
.findMemeDetail-dates {
  width: 100%;
  text-align: right;
  margin-bottom: 10px; /* 필요시 추가 */
}
.findMemeDetail-content {
  width: 100%;
  min-height: 400px;
  padding: 8px 8px;
  border-radius: 4px;
  border: 1px solid black;
  box-sizing: border-box; /* 패딩과 테두리를 포함한 높이 */
  margin-bottom: 10px;
}
.findMemeDetail-content img {
  max-width: 90%; /* 콘텐츠 영역의 이미지 최대 너비를 100%로 제한 */
  height: auto; /* 이미지 비율 유지 */
  display: block; /* 이미지 주변 여백 제거 */
  margin: 0 auto; /* 이미지 중앙 정렬 */
}

.findMemeDetail-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
}

.findMemeDetail-btn {
  width: 70px; /* 버튼 너비를 100px로 설정 */
  height: 40px; /* 버튼 높이 설정 (선택사항) */
  background-color: #219c90;
  color: white;
  border-radius: 4px;
  border: none; /* 기본 테두리 제거 */
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 3D 효과를 위한 그림자 */
  transition: background-color 0.3s ease; /* 마우스 오버 시 배경색 변경 효과 (선택사항) */
  width: 80;
}

.findMemeDetail-btn.delete {
  background-color: #d9534f; /* 삭제 버튼에 다른 색상 지정 (선택사항) */
}

.findMemeDetail-btn:hover {
  background-color: #197c72; /* 마우스 오버 시 배경색 변경 */
}

.findMemeDetail-input {
  width: 80%;
  height: 40px;
  border-radius: 8px;
  padding-left: 20px;
}

.comments-section {
  width: 100%; /* findMemeDetail의 너비에 맞춰 설정 */
  max-width: 1200px; /* 최대 너비 설정, 필요에 따라 조정 */
  margin: 0 auto; /* 가운데 정렬 */
  padding: 10px; /* 필요에 따라 패딩 추가 */
  box-sizing: border-box; /* 패딩과 테두리를 포함한 너비 */
}
