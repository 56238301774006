.uploadmeme {
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* 파일 업로드 영역 */
.upload-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border: 2px dashed #e0e0e0;
  border-radius: 8px;
  background-color: #f8f8f8;
  margin-bottom: 20px;
}

.upload-area label {
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
}

.upload-area .fileUpload-button {
  width: auto;
  background: none;
  border: none;
  color: #666;
  transition: color 0.2s;
}

.upload-area .fileUpload-button:hover {
  color: #333;
}
.notice {
  font-size: 12px;
  color: #666;
}
.upload-controls {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.upload-controls button {
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.upload-controls button:hover {
  background-color: #f0f0f0;
}
.notice {
  left: 0;
  font-size: 14px;
}

/* 미리보기 영역 */
.previews {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.preview-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  transition: transform 0.2s;
}

.preview-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.preview-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.file-checkbox {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
}

.delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.delete-button:hover {
  background: #ff4444;
  color: white;
}

/* 태그 영역 */
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  background: white;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: #e3f3f1;
  color: #219c90;
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 14px;
}

.tag button {
  background: none;
  border: none;
  margin-left: 6px;
  cursor: pointer;
  font-size: 16px;
  color: #666;
  transition: color 0.2s;
}

.tag button:hover {
  color: #ff4444;
}
.show-tags {
  display: flex;
}
.show-tags span {
  display: inline-block;
  padding: 6px 12px;
  background-color: #f3f4f6;
  border-radius: 4px;
  color: #4b5563;
  font-weight: 500;
  margin-right: 12px;
  min-width: 80px;
  text-align: center;
  border: 1px solid #e5e7eb;
  margin-bottom: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
/* 태그 선택 영역 */
.show-tags select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-bottom: 15px;
}

.subcategories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 15px 0;
}

.subcategory {
  padding: 6px 12px;
  background-color: #f0f0f0;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s;
}

.subcategory:hover {
  background-color: #e0e0e0;
}

/* 제출 버튼 */
form button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #219c90;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

form button[type="submit"]:hover {
  background-color: #1a796f;
}

/* 모달 */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90vh;
}

.modal-content img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #666;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s;
}

.close:hover {
  color: #ff4444;
}
